.menu-button  {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    background: transparent;
  }
  
  .menu-button  path {
    fill: white;
  }
  
  .menu-toggle-button {
    transition: color 0.3s ease; 
  }
  
  .menu-toggle-isClose:hover svg path {
    stroke: rgb(6,150,208); 
  }
  .menu-toggle-isOpen:hover svg path {
    stroke: rgb(8,6,79); 
  }