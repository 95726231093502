@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");


html {
  font-family: "filson-pro", "Montserrat", sans-serif, -apple-system,
    "BlmarinkMacSystemFont";
    font-feature-settings: "liga" 0;
}

*,
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

*,
:after,
:before {
  box-sizing: border-box;
  /* border: 0 solid #e2e8f0; */
}
a {
  color: inherit;
  text-decoration: inherit;
}

img,video{
  max-width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
