.footer{
    width: 100%;
    height: 568px;
    background-image: url(/public/Assets/images/footer/footer_img.jpg);
    position: relative;
    z-index: 10;
    font-family:'Montserrat','BlmarinkMacSystemFont';
    display: flex;
    justify-content: center;
}

.footer-background{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: rgba(3,1,76,0.80);
}

.footer-container{
    width: 1250px;
    height: 100%;
    position: relative;
}

.footer-left{
    position: absolute;
    width: 50%;
    padding-top: 192px;
    /* padding-left: 152px; */
    color: rgb(255,255,255);
    line-height: 1.5;
    text-size-adjust: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-weight: 500;
    z-index: 15;
    letter-spacing: 1.05px;
}

.footer-left img{
    width: 186px;
    height: 80px;
    object-fit: contain;
}

.footer-left span{
    font-weight: 600;
}

.footer-right{
    width: 50%;
    position: absolute;
    padding-top: 192px;
    color: rgb(255,255,255);
    text-size-adjust: auto;
    right:0 ;
    z-index: 15;
    letter-spacing: 1.55px;
}

.footer-right>div{
    padding-top: 3px;
    display: flex;
    justify-content: flex-end;
    /* padding-left: 127px;    */
}

.footer-right li{
    margin-bottom: 12px;
    border: 1px solid white;
    width: 350px;
    padding: 7px;
    text-align: center;
    border-bottom-right-radius: 10px ;
    border-top-left-radius: 10px;
}

.footer-right a{
    color: rgb(255,255,255);
}