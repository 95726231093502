.page-header {
    width: 100%;
    position: relative;
    min-height: 450px;
    height: 550px;
    max-height: 100vh;
  }
  
  .banner-overlay {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.8),
      hsla(0, 0%, 100%, 0.2) 25%,
      hsla(0, 0%, 100%, 0) 50%,
      hsla(0, 0%, 100%, 0)
    );
  }
  
  .banner-overlay-dark {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.2) 25%,
      transparent 50%,
      transparent
    );
  }
  
  .page-header > img,video {
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
  }
  
  .page-headText {
    position: absolute;
    z-index: 50;
    bottom: 0;
    width: 100%;
    opacity: 1;
    color: rgba(255, 255, 255);
  }
  
  .page-headText > div {
    width: 1280px;
    padding-bottom: 3rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .page-headText h1 {
    font-size: 3rem;
    max-width: 56rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .page-headText ul {
    display: flex;
    width: 100%;
    gap: 10px;
    font-weight: 600;
  }
  
  .page-headText li:first-child:hover,
  .navigateText:hover {
    color: rgb(9, 153, 211);
  }