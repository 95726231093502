@import "~react-image-gallery/styles/css/image-gallery.css";

.projects-main {
  width: 100%;
  display: grid;
  place-items: center;
}
.projects-container {
  width: 100%;
  max-width: 1280px;
  /* margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; */
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.projects-sect1 {
  padding: 40px 0;
  display: grid;
  place-items: center;
}

.projects-sect1 p {
  width: 1000px;
  font-size: 25px;
  text-align: justify;
  text-align-last: center;
  line-height: normal;
}

.projects-sect2 {
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 1rem;
}

.projects-sect2-box {
  width: 100%;
  height: auto;
  min-height: 470px;
  background-color: #03014c;
  border-bottom-right-radius: 5rem;
  display: flex;
  flex-direction: column;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.projects-sect2-box img {
  width: 100%;
  aspect-ratio: 1.4;
  object-fit: cover;
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.projects-sect2-box figure {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0; 
  overflow: hidden;
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.projects-sect2-box figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.projects-sect2-box > div:last-child {
  width: 100%;
  display: grid;
  place-items: center;
  flex: 1;
  color: rgb(255,255,255);
  text-transform: uppercase;
  padding: 0 40px;
  text-align: center;
  word-wrap: normal;
}

.projects-galery{
  width: 100%;
  background-color: rgba(237, 242, 247);  
  padding: 5.5rem 0;
  /* display: flex;
  justify-content: center; */
}

.projects-galery>div{
  /* width: 1280px; */
  /* width: 940px;   */
  /* display: flex;
  justify-content: center; */
}

.image-gallery-thumbnail-image{
  cursor: pointer;
  height: 55.2px;
  object-fit: cover;
}