.about-sect1 {
  width: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 115px;
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  font-size: 60px;
  margin: auto;
  margin-top: 70px;
}

.waviy span {
  font-family: cursive;
  position: relative;
  display: inline-block;
  color: rgb(3, 1, 76);
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

.about-sect1 > div {
  display: flex;
  flex-direction: column;
  width: 930px;
  font-size: 18px;
  line-height: 1.7;
  pointer-events: auto;
  letter-spacing: 1px;
  word-wrap: break-word;
}

.about-sect1 p:not(:last-child) {
  margin-bottom: 16px;
}

.about-sect1-p1 {
  margin-top: 90px;
}

.about-sect2,
.about-sect5 {
  width: 100%;
  height: 650px;
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 115px;
}

.about-sect2 > div,
.about-sect5 > div {
  width: 1216px;
  height: 100%;
  background-image: url(/public/Assets/images/about/about-page2.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  border-bottom-right-radius: 5rem;
}

.about-sect3 {
  width: 100%;
}

.about-sect3-upper,
.about-sect3-down {
  width: 100%;
  height: 640px;
  display: flex;
}

.about-sect3-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-sect3-upper > div:first-child,
.about-sect3-down > div:last-child {
  width: 50%;
  height: 100%;
}

.about-sect3-upper > div:last-child,
.about-sect3-down > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(8, 5, 77);
  color: rgb(7, 151, 211);
}
.about-sect3-down > div:first-child {
  background: rgb(7, 151, 211);
  color: rgb(8, 5, 77);
}

.about-sect3-text {
  width: 450px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-sect3-text h1 {
  font-size: 2.25rem;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.about-sect3-line {
  width: 50px;
  height: 2px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 35px;
}

.about-sect3-text p {
  font-size: 17px;
  line-height: 1.8;
}

.about-sect3-upper > div:last-child,
.about-sect3-down > div:first-child {
  width: 50%;
  height: 100%;
}

.about-sect4 {
  width: 100%;
  display: grid;
  justify-content: center;
  padding: 115px 0 115px 0;
}

.about-sect4 > div {
  width: 1216px;
  display: flex;
  gap: 17px;
}

.about-sect4-box {
  width: 394px;
  height: 500px;
  display: flex;
  flex-direction: column;
  color: #03014c;
  background-color: #edf2f7;
  border-bottom-right-radius: 5rem;
}

.about-sect4-headText {
  width: 100%;
  height: 20%;
  display: flex;
  padding: 0 30px;
  align-items: flex-end;
  font-size: 1.4rem;
}

.about-sect4-text {
  width: 100%;
  height: 80%;
  padding: 30px;
  /* text-align: justify; */
  /* hyphens: auto; */
  /* word-spacing: 1px; */
}

.about-sect4-text > div {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.about-sect4-text li {
  list-style-type: disc;
  margin-bottom: 4px;
}

.about-sect5 > div {
  background-image: url(/public/Assets/images/about/about-page5.jpg);
}
