#loading {
            display: block;
            text-align: center !important;
            margin: 20% auto;
        }

        .loader {
            color: #206bc4;
            position: relative;
            font-size: 11px;
            background: #206bc4;
            animation: escaleY 1s infinite ease-in-out;
            width: 1em;
            height: 4em;
            animation-delay: -0.16s;
        }
        .loader:before,
        .loader:after {
            content: '';
            position: absolute;
            top: 0;
            left: 2em;
            background: #206bc4;
            width: 1em;
            height: 4em;
            animation: escaleY 1s infinite ease-in-out;
        }
        .loader:before {
            left: -2em;
            animation-delay: -0.32s;
        }

        @keyframes escaleY {
            0%, 80%, 100% {
                box-shadow: 0 0;
                height: 4em;
            }
            40% {
                box-shadow: 0 -2em;
                height: 5em;
            }
        }