@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@800&family=Kanit:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: 'BN Pinky';
  src: url('../../../public/Assets/fonts/BN\ Pinky.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.contact-sect1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.contact-sect1 > div {
  width: 1280px;
  padding-top: 50px;
  padding-bottom: 76px;
}
.contact-sect1 form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-input {
  width: 450px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(160, 174, 192);
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Kanit";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0 30px;
}

.contact-input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgb(13, 157, 215, 0.4);
}

.contact-input-left {
  margin-right: 30px;
}

.contact-input1 {
  width: 930px;
  margin-right: 0;
}

.contact-input2 {
  width: 930px;
  min-height: 200px;
  max-width: 100%;
  resize: vertical;
  padding: 18px 30px;
  margin-bottom: 30px;
  margin-right: 0;
}

.contact-submit {
  width: 300px;
  height: 50px;
  background-color: rgb(33, 30, 95);
  color: #fff;
  font-family: "Kanit";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

.contact-sect2-map,
.contact-sect3 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-sect2-map > div,
.contact-sect3 > div {
  width: 1280px;
  display: flex;
  justify-content: center;
}

.contact-sect2-map img{
  width: 930px;
  height: 100%;
  object-fit: cover;
}

.contact-sect3 {
  padding-top: 77px;
  padding-bottom: 71px;
}

.contact-sect3 > div > div {
  width: 930px;
  display: flex;
}

.contact-address {
  width: 350px;
  padding: 23px 30px;
  border-left: 6px solid rgb(33, 30, 95);
  background: #fff;
  margin-right: 40px;
  box-shadow: 0px 2px 25px 0px rgba(0, 33, 91, 0.2);
}

.contact-address h1,
.contact-phone h1 ,
.contact-gmail h1{
  color: #41444b;
  font-family: "Kanit";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.contact-address p {
  width: 250px;
  color: rgb(33, 30, 95);
  font-family: "Kanit";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.contact-phone ,
.contact-gmail {
  width: 250px;
  height: 100px;
  display: flex;
}

.contact-phone > div:first-child,
.contact-gmail > div:first-child  {
  padding-top: 10px;
  padding-right: 15px;
}
.contact-phone {
  margin-right: 40px;
}
.contact-phone p ,
.contact-gmail p{
  color: #16213e;
  font-family: "Kanit";
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}
