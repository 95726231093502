@media (min-width: 992px) {
body {
  overflow: visible; /* Scroll hidden and visible */
}

.dropDownNav {
  transition: opacity 0.3s ease-in-out; /* Adds transition animation via opacity */
  opacity: 0; /* Opacity 0 for the item's invisibility */
  position: fixed;
  top: 0;
  width: 100vw;
  /* height: 116px; */
  /* overflow-y: hidden; */
  z-index: 1000;
  left: 0;
  opacity: 1;
}

.dropDownNav.active {
  height: auto;
  min-height: 100vh;
  max-height: 100%;
  /* overflow-y: scroll; */
  opacity: 1; /* Opacity 1 for the visible state of the element */
}

.header {
  position: fixed;
  background-color: transparent;
  opacity: 0.97;
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  padding-top: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.header-background {
  background-color: transparent !important;
  transition: background-color 0.3s ease-in-out;
}

.header-show {
  background-color: rgb(237, 242, 247, 0.8);
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.header-hidden {
  transform: translateY(-100%);
}

.header > div:first-child {
  width: 1280px;
  height: 100%;
  margin-left: 120px;
  margin-right: 120px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  width: 220px;
  height: 84px;
  padding: 8px;
  padding-left: 25px;
  position: sticky;
  z-index: 999;
  margin-top: -15px;
  background-color: rgb(7, 151, 211, 0.9);
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: background 0.3s ease-in-out;
}

.header-logo:hover {
  background-color: rgb(7, 151, 211, 0.7);
}

.header-logo-background {
  background-color: transparent !important;
}

.header-logo img {
  width: 175px;
  height: 70px;
  object-fit: contain;
}

.header button {
  position: sticky;
  top: 50px;
  left: 136px;
  z-index: 999;
  margin-bottom: 13px;
}

.header-lang-box{
  position: sticky;
  z-index: 999;
  font-size: 14px;
}

.header-lang-box>ul:last-child{
  position: absolute;
}

.header-active-lang {
  text-transform: uppercase;
  margin-top: -17px;
  width: 35px;
  height: 32px;
  text-align: center;
  border: 3px solid black;
  padding: 4px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
}

.header-active-isOpen{
  border-color: rgb(255,255,255);
  color: rgb(255,255,255);
}

.header-active-lang:hover {
  border-color: rgb(7, 151, 211);
  color: rgb(7, 151, 211);
}

.header-lang-flex{
  display: flex;
  transform: translateX(-33%);
}

.header-lang {
  display: none;
  text-transform: uppercase;
  width: 35px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  padding: 6px;
  font-weight: 600;
}

.header-lang-isOpen{
  color: rgb(255,255,255);
}

.header-active-lang:hover + .header-lang-list .header-lang {
  display: block;
}

.header-lang-list:hover .header-lang {
  display: block;
}

.header-lang:hover {
  border-bottom: 3px solid rgb(7, 151, 211);
  color: rgb(7, 151, 211);
}

.header-lang-none{
  display: none;
}

.menu-container {
  position: relative;
  display: flex;
  width: 100vh;
  max-height: 100%;
  /* z-index: 999; */
}

.menu {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 39%;
  background: rgb(7, 151, 211);
  padding-top: 100px;
  opacity: 0.9;
  transform: translateX(-101%);
  will-change: transform;
  transition: transform 0.6s cubic-bezier(0.08, 0.65, 0.53, 0.96);
  z-index: 2;
}

.new-menu {
  height: 100vh;
  transform: translateX(0%);
  transition: transform 0.6s cubic-bezier(0.08, 0.65, 0.53, 0.96);
}

.menu-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 84px;
  padding-left: 12px;
  width: 325px;
  box-sizing: border-box;
}

.menu-left > li {
  width: 295px;
  height: 54px;
  color: white;
  display: block;
  transform-origin: -20px 50%;
  font-weight: 700;
  font-size: 26px;
  padding: 5px 0 5px 0;
  will-change: transform, opacity, filter;
  list-style-type: none;
  transform: scale(1);
  filter: none;
  transition: transform 0.5s, opacity 0.5s, filter 0.5s;
  margin-bottom: 5px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
  transition-duration: 0.3s;
}

.menu-left > li:nth-child(2),
.menu-left > li:nth-child(3) {
  cursor: pointer;
}

.menu-left > li:hover {
  position: relative;
  padding-left: 20px;
  color: #03014c;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
  transition-duration: 0.3s;
}

.menu-left > li:hover::before {
  content: "";
  position: absolute;
  width: 295px;
  left: 20px;
  bottom: 0;
  border-bottom: 10px solid #03014c;
}

.project-line {
  position: relative;
  padding-left: 20px !important;
  color: #03014c !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-property: all !important;
  transition-duration: 0.3s !important;
}

.project-line::before {
  content: "";
  position: absolute;
  width: 295px;
  left: 20px;
  bottom: 0;
  border-bottom: 10px solid #03014c;
}

.menu-left > li > a {
  cursor: pointer;
}

.menu2 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 61%;
  background: rgb(8, 5, 77);
  padding-top: 100px;
  opacity: 0.9;
  transform: translateX(100%);
  will-change: transform;
  transition: transform 0.6s cubic-bezier(0.08, 0.65, 0.53, 0.96);
  z-index: 1;
}

.new-menu2 {
  height: 100vh;
  transform: translateX(0%);
  transition: transform 0.6s cubic-bezier(0.08, 0.65, 0.53, 0.96);
}

/* .navbar-img{
  position: absolute;
  width: 205px;
  height: 90px;
  top:24px;
  left: 46px;
}

.navbar-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
} */

.menu-right {
  display: none;
  width: 760px;
  /* padding-top: 92px; */
  color: white;
  padding-left: 48px;
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  transform: translate(294px, -99px);
  cursor: default;
}

.menu-right-product {
  transform: translate(294px, -169px);
}

.menu-right-project-show,
.menu-right-product-show {
  display: block;
}

.menu-right li {
  margin-bottom: 15px;
  max-width: 400px;
  width: fit-content;
}

/* .menu-right li:nth-child(5) {
  margin-bottom: 8px;
} */

.menu-right li:not(:last-child):hover {
  color: rgb(7, 151, 211);
}

.menu-right li > p {
  cursor: text;
}

.menu-right img {
  width: 256px;
  height: 256px;
  object-fit: cover;
}

.menu-right p {
  font-size: 14px;
  font-weight: 300;
  width: 256px;
}

.all-project-line {
  width: fit-content !important;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 4px;
  border-bottom: 4px solid white;
}

.all-project-line:hover {
  border-color: rgb(7, 151, 211);
}

/* @keyframes wipe-in-right {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }
  
  [transition-style="in:wipe:right"] {
    animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both;
  } */
}

/* Tablet mode (width between 768px and 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .dropDownNav {
    width: 100vw;
    height: 80px;
  }
  .header {
    height: 80px;
    padding-top: 0.5rem;
  }
  .header > div:first-child {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .header-logo {
    width: 180px;
    height: 70px;
  }
  .menu {
    width: 50%;
  }
  .menu-left > li {
    font-size: 20px;
  }
}

/* Phone mode (max-width: 767px) */
@media (max-width: 767px) {
  .dropDownNav {
    width: 100vw;
    height: 60px;
  }
  .header {
    height: 60px;
    padding-top: 0.25rem;
  }
  .header > div:first-child {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .header-logo {
    width: 150px;
    height: 60px;
  }
  .menu {
    width: 100%;
  }
  .menu-left > li {
    font-size: 18px;
  }
  .header-active-lang {
    font-size: 12px;
    width: 30px;
    height: 30px;
  }
}