@import url("https://fonts.googleapis.com/css?family=Poppins");
@media (min-width: 992px) {
  .head-text {
    padding: 50px;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 64px;
    color: #0098d5;
    letter-spacing: 10px;
  }

  .section1 {
    font-size: 0; /* Resets the surrounding text space */
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .section1 video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }

  .section1-background {
    width: 100%;
    position: absolute;
    background: linear-gradient(
      180deg,
      #878da2,
      hsla(0, 0%, 100%, 0.2) 25%,
      hsla(0, 0%, 100%, 0) 50%,
      hsla(0, 0%, 100%, 0)
    );
    height: 100vh;
    top: 0;
  }

  .section1-headText {
    position: absolute;
    width: 100%;
    height: auto;
    padding: 0 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    line-height: 84px;
    letter-spacing: 4px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    color: transparent;
    font-size: 84px;
    font-weight: 900;
    text-align: center;
  }

  .section2 {
    display: flex;
    width: 100%;
    font-size: 0; /* Resets the surrounding text space */
  }

  .section2 > div:first-child {
    width: 50%;
    background-color: rgb(3, 1, 76);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 75px;
    margin-top: -135px;
    height: auto;
    font-weight: 700;
    line-height: 50px;
    z-index: 1;
  }
  .section2-text {
    width: 525px;
    font-size: 36px;
    font-family: "Montserrat", sans-serif, -apple-system;
  }
  .section2-text span {
    color: #0098d5;
  }
  .section2-text p {
    color: white;
  }

  .section2 > div:last-child {
    width: 50%;
  }

  .section2 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: 1s ease;
  }

  .section2 img:hover {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: 1s ease-in-out;
  }

  .section2-2 {
    width: 100%;
    height: 775px;
    background: #141e30;
    background: RGB(247 242 242);
    font-family: "Poppins", sans-serif;
    overflow: hidden;
  }
  .section2-2 > div {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  .card-container {
    display: flex;
    flex-direction: column;
  }

  .card:first-child {
    margin-bottom: 30px;
  }

  .card {
    /* border-radius: 16px; */
    /* border-bottom-right-radius: 32px; */
    width: 320px;
    height: 260px;
    background-image: url(/public/Assets/images/homePage/section2-card1.jpg);
    background-size: cover;
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 50px rgba(0, 0, 0, 0.85);
  }

  .card1 {
    height: 550px;
    margin: 0;
  }

  .card2 {
    background-image: url(/public/Assets/images/homePage/section2-card2.jpg);
  }

  .card3 {
    background-image: url(/public/Assets/images/homePage/section2-card3.jpg);
  }
  .card4 {
    background-image: url(/public/Assets/images/homePage/section2-card4.jpg);
  }
  .card5 {
    background-image: url(/public/Assets/images/homePage/section2-card5.jpg);
  }
  .card:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.92);
    opacity: 0.8;
  }
  .card .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .card .img span {
    width: 25%;
    height: 100%;
    background: url(/public/Assets/images/homePage/section2-card1.jpg);
    background-size: cover;
    transition: 0.5s;
  }
  .card2 .img span {
    background-image: url(/public/Assets/images/homePage/section2-card2.jpg);
  }
  .card3 .img span {
    background-image: url(/public/Assets/images/homePage/section2-card3.jpg);
  }
  .card4 .img span {
    background-image: url(/public/Assets/images/homePage/section2-card4.jpg);
  }
  .card5 .img span {
    background-image: url(/public/Assets/images/homePage/section2-card5.jpg);
  }
  .card .img span:nth-child(1) {
    background-position: 0;
    transition-delay: 0;
  }
  .card .img span:nth-child(2) {
    background-position: 33.33333%;
    transition-delay: 0.1s;
  }
  .card .img span:nth-child(3) {
    background-position: 66.66666%;
    transition-delay: 0.2s;
  }
  .card .img span:nth-child(4) {
    background-position: 100%;
    transition-delay: 0.3s;
  }

  .card:hover .img > span {
    transform: translateY(-100%);
  }

  .card:hover .content {
    transform: translateY(0%);
    transition: 1s;
    transition-delay: 0.1s;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 29px;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
  }

  .content > h2,
  h4 {
    -ms-flex: 1; /* IE 10 */
    flex: 1;
    text-align: center;
  }

  .content > div {
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    padding-top: 20px;
    display: grid;
    place-items: center;
  }

  .content > div .homePage-projects {
    padding: 0.6rem 1rem;
    font-weight: 600;
    background-color: #0098d5;
    color: rgba(255, 255, 255);
    font-size: 20px;
  }

  .content > div .homePage-projects:hover {
    background-color: rgba(3, 1, 76);
  }

  .content-icon {
    margin-left: 5px;
    font-size: 40px;
    transform: translateY(14px);
  }

  .section3 {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .section3 > div {
    width: 33.333333%;
  }
  .section3 > div:first-child {
    /* background-color: rgb(98, 210, 168); */
    background-color: #0098d5;
  }
  .section3 > div:nth-child(2) {
    /* background-color: rgb(0, 152, 213); */
    background-color: rgb(242 98 107);
  }
  .section3 > div:last-child {
    /* background-color: rgb(231, 125, 124); */
    background-color: #62d2a8;
  }

  .section3 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3/2;
    transition: 1s ease;
  }

  .section3 img:hover {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transition: 1s ease;
  }

  .section3-upper {
    height: auto !important;
    font-size: 0;
    position: relative;
  }
  .section3-upper > div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }
  .circular-box1 {
    /* background-color: rgb(98, 210, 168); */
    background-color: #0098d5;
  }

  .circular-box2 {
    /* background-color: rgb(0, 152, 213); */
    background-color: rgb(242 98 107);
  }

  .circular-box3 {
    /* background-color: rgb(231, 125, 124); */
    background-color: #62d2a8;
  }

  .section3-bottom {
    width: 100%;
    height: 300px;
    padding: 48px 64px;
    text-align: center;
  }

  .section3-bottom p:first-child {
    font-size: 36px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .section3-bottom p:last-child {
    font-size: 20px;
    font-weight: 600;
  }

  .section4 {
    width: 100%;
    height: 750px;
    background-color: rgb(232 235 237);
  }

  .section4-headText:hover {
    color: rgb(3, 1, 76);
  }

  .section4 > div:first-child {
    padding: 50px;
    display: flex;
    justify-content: center;
  }
  .section4-products {
    display: flex;
    width: 100%;
  }

  .section4-products > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
  }

  .section4-text {
    font-size: 35px;
    line-height: 70px;
    padding-left: 50px;
    letter-spacing: 8px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .section4-text-box {
    display: flex;
  }

  .section4-text-box > div:first-child {
    flex: 1;
  }

  .section4-button > button {
    background-color: rgb(7, 151, 211);
    background-repeat: no-repeat;
    margin-left: 15px;
    margin-top: 20px;
    padding: 5px 20px;
    /* padding: 0 20px; */
    cursor: pointer;
    font-size: 20px;
    color: #f4f4f4;
    border: 1px solid rgb(3, 1, 76, 0.4);
    position: relative;
    overflow: hidden;
    margin-left: 40px;
    display: flex;
    justify-content: center;
  }
  .section4-button > button:hover {
    box-shadow: 1px 1px 25px 10px rgb(3, 1, 76, 0.4);
  }
  .section4-button > button:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgb(3, 1, 76, 0.4),
      transparent
    );
    transition: all 650ms;
  }

  .section4-button > button:hover:before {
    left: 100%;
  }

  .section4-button span {
    transform: translateY(3px);
  }

  .section4-icon {
    margin-top: 0;
    margin-left: 10px;
    font-size: 30px;
    /* transform: translateY(8px); */
  }

  .product-div {
    width: fit-content;
  }

  .product-div > div {
    cursor: default;
    background-image: linear-gradient(
      to right,
      #54b3d6,
      #54b3d6 50%,
      rgb(3, 1, 76) 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  }

  .product-div > div:before {
    content: "";
    background: #54b3d6;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }

  .product-div > div:hover,
  .section4-text.hovered1 .products-link1,
  .section4-text.hovered2 .products-link2,
  .section4-text.hovered3 .products-link3,
  .section4-text.hovered4 .products-link4 {
    background-position: 0;
  }

  .product-div > div:hover::before,
  .section4-text.hovered1 .products-link1::before,
  .section4-text.hovered2 .products-link2::before,
  .section4-text.hovered3 .products-link3::before,
  .section4-text.hovered4 .products-link4::before {
    width: 100%;
  }

  .section4-products > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 540px;
    width: 40%;
  }

  .section4-gallery {
    --g: 8px; /* the gap */
    --s: 400px; /* the size */
    display: grid;
    border-radius: 50%;
  }
  .section4-gallery > img {
    grid-area: 1/1;
    width: 450px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    transform: translate(var(--_x, 0), var(--_y, 0));
    cursor: default;
    z-index: 0;
    transition: 0.3s, z-index 0s 0.3s;
  }
  .section4-gallery img:hover,
  .section4-gallery.hovered1 .products-img1,
  .section4-gallery.hovered2 .products-img2,
  .section4-gallery.hovered3 .products-img3,
  .section4-gallery.hovered4 .products-img4 {
    --_i: 1;
    z-index: 1;
    transition: transform 0.2s, clip-path 0.3s 0.2s, z-index 0s;
  }
  .section4-gallery:hover img,
  .section4-gallery.hovered1 img,
  .section4-gallery.hovered2 img,
  .section4-gallery.hovered3 img,
  .section4-gallery.hovered4 img {
    transform: translate(0, 0);
  }
  .section4-gallery > img:nth-child(1) {
    clip-path: polygon(
      50% 50%,
      calc(50% * var(--_i, 0)) calc(120% * var(--_i, 0)),
      0 calc(100% * var(--_i, 0)),
      0 0,
      100% 0,
      100% calc(100% * var(--_i, 0)),
      calc(100% - 50% * var(--_i, 0)) calc(120% * var(--_i, 0))
    );
    --_y: calc(-1 * var(--g));
  }
  .section4-gallery > img:nth-child(2) {
    clip-path: polygon(
      50% 50%,
      calc(100% - 120% * var(--_i, 0)) calc(50% * var(--_i, 0)),
      calc(100% - 100% * var(--_i, 0)) 0,
      100% 0,
      100% 100%,
      calc(100% - 100% * var(--_i, 0)) 100%,
      calc(100% - 120% * var(--_i, 0)) calc(100% - 50% * var(--_i, 0))
    );
    --_x: var(--g);
  }
  .section4-gallery > img:nth-child(3) {
    clip-path: polygon(
      50% 50%,
      calc(100% - 50% * var(--_i, 0)) calc(100% - 120% * var(--_i, 0)),
      100% calc(100% - 120% * var(--_i, 0)),
      100% 100%,
      0 100%,
      0 calc(100% - 100% * var(--_i, 0)),
      calc(50% * var(--_i, 0)) calc(100% - 120% * var(--_i, 0))
    );
    --_y: var(--g);
  }
  .section4-gallery > img:nth-child(4) {
    clip-path: polygon(
      50% 50%,
      calc(120% * var(--_i, 0)) calc(50% * var(--_i, 0)),
      calc(100% * var(--_i, 0)) 0,
      0 0,
      0 100%,
      calc(100% * var(--_i, 0)) 100%,
      calc(120% * var(--_i, 0)) calc(100% - 50% * var(--_i, 0))
    );
    --_x: calc(-1 * var(--g));
  }

  .section4-2 {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 27px;
  }

  .section4-2 > div:first-child {
    width: 50%;
    max-height: 570px;
    min-height: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section4-2 img {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
    font-size: 0; /* Resets the surrounding text space */
  }

  .section4-2-circular {
    position: absolute;
    width: 250px;
    height: 250px;
    background-color: rgb(245, 210, 132);
    border-radius: 50%;
    z-index: 1;
    opacity: 0.8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section4-2-circular:hover {
    opacity: 1;
  }

  .section4-2-circular p {
    font-size: 33px;
    color: rgb(3, 1, 76);
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.05em;
    text-align: center;
    font-weight: 700;
  }

  .section4-2 > div:last-child {
    width: 50%;
    height: auto;
    background-color: rgb(245, 210, 132);
    border-bottom-left-radius: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 168px 95px;
  }

  .section4-2_2 p {
    font-size: 33px;
    line-height: 43px;
    font-weight: 700;
    color: rgb(3, 1, 76);
  }

  .section5 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 47px;
  }
  .section5 > div:first-child {
    padding: 50px;
    display: flex;
    justify-content: center;
  }
  .partners-img {
    display: flex;
    padding: 30px;
    max-width: 1060px;
    flex-wrap: wrap;
    gap: 30px;
  }
  .partners-img > div {
    width: 175px;
    height: 140px;
  }
  .partners-img > div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .section6 {
    width: 100%;
    height: 710px;
    background-color: rgb(3, 1, 76);
  }

  .section6 > div:first-child {
    display: flex;
    font-size: 27px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: 80px;
    gap: 10px;
    padding: 0;
  }

  .section6 h1 {
    margin-top: -12px;
  }

  .section6-line {
    width: 100px;
    height: 5px;
    background-color: white;
    margin-top: -10px;
  }

  .section6 > div:last-child {
    width: 100%;
    height: 10px;
    background-color: rgb(3, 1, 76);
  }

  .e-catalog {
    width: 100%;
    height: 610px;
  }

  .section6 > div:nth-child(2) img {
    width: 100%;
    height: 610px;
    object-fit: contain;
    cursor: pointer;
  }

  .section7 {
    width: 100%;
    display: flex;
    height: 600px;
    margin-top: 100px;
  }
  .section7 > div:first-child {
    width: 50%;
    max-height: 600px;
    background-color: rgb(0, 152, 213);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 75px;
    height: auto;
    font-weight: 700;
    line-height: 50px;
  }

  .section7-text {
    font-family: "Montserrat", sans-serif, -apple-system;
    width: 451px;
    font-size: 40px;
  }

  .section7-text p {
    color: white;
  }

  .section7-number {
    font-size: 42px;
    color: rgb(3, 1, 76);
  }

  .section7-slider {
    width: 50%;
    height: 600px;
    margin-top: 100px;
    z-index: 15;
  }
  .section7-slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    border-bottom-left-radius: 75px;
  }
}
