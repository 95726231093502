.services-description {
  width: 1179px;
  height: 225px;
  background-color: rgba(237, 242, 247);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: rgb(3, 1, 76);
  display: grid;
  place-items: center;
  text-align: justify;
  margin-bottom:3rem;
}

.services-page {
  width: 1179px;
  display: grid;
  row-gap: 2rem;
  margin: auto;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
}

.services-box,
.services-about {
  height: 433px;
}

.services-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-about {
  display: flex;
  flex-direction: column;
  padding: 83px 77px 52px 50.5px;
  color: rgb(255, 255, 255);
}

.services-about h1 {
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 1.7px;
  margin-bottom: 33px;
}

.services-about p {
  height: 140px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 33px;
}

.services-about button {
  width: 240px;
  height: 64px;
  background-color: Transparent;
  background-repeat: no-repeat;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #f4f4f4;
  border: 1px solid rgb(7, 151, 211);
  position: relative;
  overflow: hidden;
}

.services-about:nth-child(odd) button {
    border: 1px solid rgb(3,1,76);
}

.services-about button:hover {
  box-shadow: 1px 1px 25px 10px rgba(7, 151, 211, 0.4);
}

.services-about:nth-child(odd) button:hover {
  box-shadow: 1px 1px 25px 10px rgb(3,1,76,0.4);
}

.services-about button:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(7, 151, 211, 0.4),
    transparent
  );
  transition: all 650ms;
}

.services-about:nth-child(odd) button::before{
    background: linear-gradient(
    120deg,
    transparent,
    rgb(3,1,76,0.4),
    transparent
  );
}

.services-about button:hover:before {
  left: 100%;
}

.services-box:nth-child(odd) img {
  border-top-left-radius: 5rem;
}

.services-box:nth-child(even) img {
  border-bottom-right-radius: 5rem;
}

.services-about:nth-child(odd) {
  background-color: rgb(7, 151, 211);
  border-top-left-radius: 5rem;
}
.services-about:nth-child(even) {
  border-bottom-right-radius: 5rem;
  background-color: rgb(3,1,76);
}
