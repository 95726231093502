.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: rgb(0,152,213);
  transform-origin: 0%;
  z-index: 1100;
}

::-webkit-scrollbar {
  height: 10px;
  width: 16px;
  background: white;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: rgb(3,1,76);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #fff3;
}
