@keyframes fadeInRight {
  0%{
    transform: translateX(100px);
  }
  100%{
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0%{
    transform: translateX(-100px);
  }
  100%{
    transform: translateX(0);
  }
}

.upButtonHidden,
.upButtonShow{
  position: fixed;
  right: -100px;
  width: 40px;
  height: 40px;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  bottom: 125px;
  color: rgb(255,255,255);
  cursor: pointer;
  z-index: 950;
  padding: 12px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  animation: fadeOutRight 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
}

.upButtonShow{
  right: 0px;
  animation: fadeInRight 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
}

.upButtonShow:hover {
  background: rgba(0, 0, 0, 0.8);
}


