.services-main {
  width: 100%;
  display: grid;
  place-items: center;
}

.services-container {
  width: 100%;
  max-width: 1280px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
  place-items: center;
}


