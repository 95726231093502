.services-product-box {
  width: 1280px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  background-color: rgb(3, 1, 76);
  color: rgb(255, 255, 255);
}

.services-product:hover {
  background-color: rgb(7, 151, 211);
}

.services-product {
  width: auto;
  padding: 20px 5px;
  cursor: pointer;
  border: none;
  background-color: rgb(3, 1, 76);
  color: rgb(255, 255, 255);
}

.productActive {
  background-color: rgb(7, 151, 211);
}

.services-product-sect1 {
  width: 1280px;
  padding: 3rem 0;
  /* background-color: rgba(237, 242, 247); */
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  row-gap: 2rem;
}

.services-product-sect1>div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  row-gap: 2rem;
}

.services-product-img{
  max-height: 597px;
  border: 1px solid rgb(3, 1, 76);
  border-right: none;
}

.services-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-product-text {
  /* min-height: 390px; */
  background-color: rgb(7, 151, 211);
  color: rgb(255, 255, 255);
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  gap: 15px;
  border-bottom-right-radius: 7rem;
  border: 1px solid rgb(3, 1, 76);
  border-left: none;
}

.services-product-text h1{
  color: rgb(3, 1, 76);
}
